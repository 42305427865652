// in dataProvider.js
import { flatten } from "flat"
import { supabaseDataProvider } from "ra-supabase-core"
import { DataProvider, GetListParams } from "react-admin"
import { v4 } from "uuid"
import { supabase as supabaseClient } from "./supabase"
import { withLifecycleCallbacks } from "./withLifecycleCallbacks"
const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env

export const primaryKeys = new Map([
  ["tools", ["tool_id"]],
  ["t_tools", ["tool_id"]],
  ["tool_notes", ["tool_note_id"]],
  ["tool_scans", ["tool_scan_id"]],
  ["brands", ["name"]],
  ["categories", ["name"]],
  ["org_categories", ["name"]],
  ["crew_members", ["crew_member_id"]],
  ["projects", ["project_id"]],
  ["clients", ["client_id"]],
  ["organizations", ["organization_id"]],
  ["maintenance", ["maintenance_id"]],
  // ["service_view", ["maintenance_id"]],
  ["groups", ["group_id"]],
  ["reorders", ["reorder_id"]],
  ["service_templates", ["service_template_id"]],
  ["tool_services", ["tool_service_id"]],
  ["service_records", ["service_record_id"]],
  ["cost_codes", ["cost_code_id"]],
  ["accounting_report", ["accounting_report_id"]],
  ["monthly_accounting_report", ["monthly_accounting_report_id"]],
  ["accounting", ["monthly_accounting_report_id"]], // TODO: change this to accounting_id
  ["invoices", ["invoice_id"]],
  ["invoice_items", ["invoice_item_id"]],
  ["enabled_modules", ["enabled_module_id"]],
])

export const dataProvider = withLifecycleCallbacks(
  supabaseDataProvider({
    instanceUrl: REACT_APP_SUPABASE_URL,
    apiKey: REACT_APP_SUPABASE_KEY,
    supabaseClient,
    primaryKeys,
  }),
  [
    // https://github.com/raphiniert-com/ra-data-postgrest/issues/124
    {
      resource: "*",
      beforeGetList: async (params: GetListParams) => {
        params.filter = flatten(params.filter, { safe: true })
        // Adding @in support we need to convert the array into a comma separated list with () around it
        Object.keys(params.filter)
          .filter((key) => key.endsWith("@in"))
          // For some reason we have issues with strings showing up in the in filter
          .filter((key) => Array.isArray(params.filter[key]))
          .forEach((key) => {
            params.filter[key] = `(${params.filter[key]?.join(",")})`
          })

        // Support full text search
        // We are doing this rather than wfts so we can get partial matches on the final word
        Object.keys(params.filter)
          .filter((key) => key.endsWith("@fts"))
          .forEach((key) => {
            params.filter[key] = params.filter[key]
              .trim()
              // Postgres escapes single quotes by doubling them
              .replaceAll("'", "''")
              .split(" ")
              .filter(Boolean)
              // We need to wrap each word in single quotes or some characters will be special
              // Adding a :* to the end of the word allows for partial matches
              .map((word: string) => `'${word}':*`)
              ?.join(" & ")
          })
        if (params.sort.field.includes(".")) {
          // we need to restructure it to be table(column) instead of table.column
          params.sort.field = params.sort.field.replace(".", "(") + ")"
        }
        if (params.filter.organization_id) {
          params.filter.organization_id =
            params.filter.organization_id.replaceAll('"', "")
        }
        return params
      },
      // React admin expects image fields to be objects with a src key
      afterRead: async (
        data: any,
        _dataProvider: DataProvider,
        resource: string
      ) => {
        if (resource === "tools") {
          data.primary_photo = { src: data.primary_photo }
          data.tool_photo = { src: data.tool_photo }
          data.label_photo = { src: data.label_photo }
          data.transfer_photo = { src: data.transfer_photo }
          data.additional_photo_0 = { src: data.additional_photo_0 }
          data.additional_photo_1 = { src: data.additional_photo_1 }
          data.additional_photo_2 = { src: data.additional_photo_2 }
          data.additional_photo_3 = { src: data.additional_photo_3 }
          data.additional_photo_4 = { src: data.additional_photo_4 }
          if (data.attachment) {
            data.attachment = {
              src: data.attachment,
              title: data.attachment.split("/").pop() || "",
            }
          }
          if (data.attachments) {
            data.attachments = data.attachments.map((attachment: any) => ({
              src: attachment,
              title: attachment.split("/").pop() || "",
            }))
          }
        }
        if (
          resource === "maintenance" ||
          // resource === "service_view" ||
          resource === "service_records" ||
          resource === "service_templates"
        ) {
          if (data.attachment) {
            data.attachment = {
              src: data.attachment,
              title: data.attachment?.split("/").pop(),
            }
          }
        }
        if (resource === "organizations") {
          data.logo = { src: data.logo }
        }
        if (resource === "tool_scans") {
          data.scan_photo = { src: data.scan_photo }
        }
        if (resource === "tool_notes") {
          data.photo = { src: data.photo }
        }
        if (resource === "categories") {
          data.image_url = { src: data.image_url }
        }

        return data
      },
      beforeSave: async (data: any, _: DataProvider, resource: string) => {
        // Save Files
        if (Object.keys(data).length === 0) return data
        if (data.id === undefined) {
          data.id = v4()
        }
        const newFiles = (
          await Promise.all(
            Object.keys(data)
              .filter((key) => data[key]?.rawFile instanceof File)
              .map((key) => [key, data[key], data.id])
              .map(async ([key, file, id]: any) => {
                const { data, error } = await supabaseClient.storage
                  .from(`user-content/${resource}/${id}`)
                  .upload(
                    file.title.replaceAll(
                      /[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/g,
                      " "
                    ),
                    file.rawFile,
                    {
                      upsert: true,
                      cacheControl: "public, max-age=31536000, immutable",
                    }
                  )
                if (error) throw error
                const src = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public/user-content/${resource}/${id}/${data?.path}`
                return { [key]: { src } }
              })
          )
        ).reduce((acc, val) => ({ ...acc, ...val }), {})
        // Specifically repeat this for attachments field which is an array of files
        if (data.attachments) {
          const newAttachments = await Promise.all(
            data.attachments.map(async (file: any) => {
              if (!(file.rawFile instanceof File)) return file
              const { data: saveData, error } = await supabaseClient.storage
                .from(`user-content/${resource}/${data.id}`)
                .upload(
                  file.title.replaceAll(
                    /[\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/g,
                    " "
                  ),
                  file.rawFile,
                  {
                    upsert: true,
                    cacheControl: "public, max-age=31536000, immutable",
                  }
                )
              if (error) throw error
              const src = `${REACT_APP_SUPABASE_URL}/storage/v1/object/public/user-content/${resource}/${
                data.id ?? v4()
              }/${saveData?.path}`
              return { src }
            })
          )
          data.attachments = newAttachments
        }

        const newData = { ...data, ...newFiles }
        // swap objects with src property to just the src as a string
        Object.keys(newData)
          .filter(
            (key) =>
              typeof newData[key] === "object" &&
              newData[key]?.src !== undefined
          )
          .forEach((key) => {
            newData[key] = newData[key].src
          })

        // same for attachments array
        if (newData.attachments) {
          newData.attachments = newData.attachments.map(
            (attachment: any) => attachment.src
          )
        }

        // TODO: figure this one out
        delete newData.primary_photo
        return newData
      },
      // delete one requires a result which soft delete doesn't provide.
      // We need to override the Accept header to allow that

      // Consider filing a bug here.
      beforeDelete: async (params: any) => {
        if (params.meta == null) {
          params.meta = { headers: {} }
        }
        params.meta.headers.Accept = "*/*"
        return params
      },
      beforeUpdate: async (params: any) => {
        if (params.meta == null) {
          params.meta = { headers: {} }
        }
        params.meta.headers.Accept = "*/*"
        return params
      },
      beforeUpdateMany: async (params: any) => {
        if (params.meta == null) {
          params.meta = { headers: {} }
        }
        params.meta.headers.Accept = "*/*"
        return params
      },
    },
    // {
    //   resource: "tools",
    //   beforeSave: async (data: any) => {
    //     // TODO: Move this to the backend, if a tool is inserted or updated with an inactive_status, set the state to INACTIVE
    //     // If it had an inactive_status and it's removed, set the state to ACTIVE
    //     if (data.inactive_status === null && data.state === "INACTIVE") {
    //       data.state = "ACTIVE"
    //       return data
    //     }
    //     if (data.inactive_status !== null) {
    //       data.state = "INACTIVE"
    //       return data
    //     }
    //     return data
    //   },
    // },
  ]
)
