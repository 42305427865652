import camelcaseKeys from "camelcase-keys"
import { supabase } from "./supabase"

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
// eslint-disable-next-line import/no-anonymous-default-export
export const dataProvider = {
  getList: async (resource, params) => {
    const hh2_params = Object.assign({}, params.filter)
    hh2_params.page = params.pagination.page - 1
    hh2_params.limit = params.pagination.perPage
    delete hh2_params.q
    delete hh2_params.organization_id

    const response = await supabase.functions.invoke("hh2", {
      body: {
        organization_id: params.filter.organization_id,
        resource,
        hh2_params,
      },
    })
    if (response.error) throw new Error(response.error)
    const rawData = camelcaseKeys(JSON.parse(response.data))
    const filteredData = rawData.filter((item) => {
      return Object.keys(params.filter).every((key) => {
        if (["organization_id", "job", "keyword"].includes(key)) {
          return true
        }
        if (key === "q") {
          return Object.values(item).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(params.filter.q.toLowerCase())
            }
            return false
          })
        }
        if (key.includes("@in")) {
          return params.filter[key].includes(item[key.replace("@in", "")])
        }
        if (key.includes("@fts")) {
          return item[key.replace("@fts", "")].includes(params.filter[key])
        }
        // console.log({ key, value: item[key], filter: params.filter[key] })
        return item[key] === params.filter[key]
      })
    })
    const sortedData = filteredData.sort((a, b) => {
      if (parseInt(a[params.sort.field])) {
        return parseInt(a[params.sort.field]) - parseInt(b[params.sort.field])
      }
      if (typeof a[params.sort.field] === "string") {
        return a[params.sort.field].localeCompare(b[params.sort.field])
      }
      return a[params.sort.field] - b[params.sort.field]
    })
    const data = params.sort.order === "ASC" ? sortedData : sortedData.reverse()
    return { data, total: data.length }
  },

  getOne: async (resource, params) => {
    const hh2_params = params
    const response = await supabase.functions.invoke("hh2", {
      body: {
        action: "get",
        organization_id: params.meta.organization_id,
        resource,
        hh2_params,
      },
    })
    if (response.error) throw new Error(response.error)
    const data = camelcaseKeys(JSON.parse(response.data))
    return { data }
  },

  getMany: async (resource, params) => {
    const responses = await Promise.all(
      params.ids.map((id) => {
        const hh2_params = { id }
        return supabase.functions.invoke("hh2", {
          body: {
            action: "get",
            organization_id: params.meta.organization_id,
            resource,
            hh2_params,
          },
        })
      })
    )
    if (responses.some((response) => response.error)) {
      throw new Error(responses.find((response) => response.error).error)
    }
    const data = responses.map((response) =>
      camelcaseKeys(JSON.parse(response.data))
    )
    return { data }
  },

  getManyReference: (resource, params) => {
    throw new Error("getManyReference not implemented")
  },

  update: (resource, params) => {
    throw new Error("update not implemented")
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) => {
    throw new Error("updateMany not implemented")
  },

  create: async (resource, params) => {
    const hh2_body = camelcaseKeys(params.data, {
      pascalCase: true,
      deep: true,
    })
    const response = await supabase.functions.invoke("hh2", {
      body: {
        organization_id: params.meta.organization_id, // TODO: This might go into the data and need to be stripped out on the server
        resource,
        action: "create",
        hh2_body,
      },
    })

    if (response.error) throw new Error(response.error)
    const data = camelcaseKeys(JSON.parse(response.data))
    if (typeof data === "string") {
      return { data: { id: data } }
    }
    if (data.errors) {
      console.error(data.errors)
      throw new Error(data.errors)
    }
    if (data.id) {
      return { data }
    }
  },

  delete: (resource, params) => {
    throw new Error("delete not implemented")
  },

  deleteMany: (resource, params) => {
    throw new Error("deleteMany not implemented")
  },
}
