import { useGetIdentity, useGetOne, useStore } from "react-admin"
import { NIL } from "uuid"
export function useOrganizationId() {
  const { identity } = useGetIdentity()

  const { organizationId } = identity || { organizationId: NIL }
  return useStore("organizationId", organizationId)
}

export function useOrganization() {
  const [organizationId] = useOrganizationId()
  return useGetOne(
    "organizations",
    { id: organizationId },
    { enabled: !!organizationId && organizationId !== NIL }
  )
}
