import { Datagrid, FunctionField, TextField } from "react-admin"
import { CostCodeField } from "../Components/HH2/CodeCodeField"
import { MoneyField } from "../Components/MoneyField"

export function InvoiceItemDatagrid(props) {
  return (
    <Datagrid
      rowClick="edit"
      {...props}
      sort={{ field: "hh2_cost_code_id", order: "ASC" }}
    >
      <CostCodeField label="Cost Code" source="hh2_cost_code_id" />
      <TextField component="pre" source="description" />
      <FunctionField
        source="quantity"
        textAlign="right"
        render={({ quantity, unit }) => `${quantity} ${unit ?? ""}`}
      />
      <MoneyField source="unit_price" textAlign="right" />
      <MoneyField source="line_total" textAlign="right" />
      {/* <TaxGroupField source="tax_group_id" /> */}
      {/* <DateField source="created_at" />
          <DateField source="updated_at" /> */}
    </Datagrid>
  )
}
