import { useCallback } from "react"
import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useDataProvider,
  useRedirect,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AmountTypeInput } from "../Components/AmountTypeInput"
const USD = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})
const DateFormatter = Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "2-digit",
})

export function InvoiceItemFromAccounting({ accountingRecords }) {
  const [organization_id] = useOrganizationId()
  const redirect = useRedirect()
  const [create] = useCreate()
  const dataProvider = useDataProvider()
  const handleCreate = useCallback(
    (record) => {
      Promise.all(
        accountingRecords.map(async (accountingRecord) => {
          const {
            tool_id,
            quantity,
            period_rent,
            capped_rent,
            bill_type,
            period_start,
            period_end,
            remaining_cap,
            value,
            cost_code: original_cost_code, // TODO: clean this up
          } = accountingRecord
          const accountingCostCode = original_cost_code
            ? original_cost_code.split(" - ")[0]
            : "" // TODO: clean this up

          const { data: tool } = await dataProvider.getOne("tools", {
            id: tool_id,
          })

          const { data: costCodes } = await dataProvider.getList(
            "hh2/cost_codes",
            {
              filter: {
                organization_id,
                q: accountingCostCode,
              },
              pagination: { page: 1, perPage: 10 },
              sort: { field: "code", order: "ASC" },
            }
          )
          const costCode = costCodes.find(
            (code) => code.code === accountingCostCode
          )

          // quantity and unit and unit price should come from the accounting record
          // line_total is the capped amount
          const unit_price = period_rent
          const line_total = capped_rent
          const unit = quantity === 1 ? bill_type : `${bill_type}s`
          const {
            invoice_id,
            description: customDescription,
            tax_group_id,
            amount_type,
            tax_status_id,
          } = record
          const startDate = new Date(period_start)
          const endDate = new Date(period_end)
          const description =
            `${tool.display_name}\nPeriod: ${DateFormatter.formatRange(
              startDate,
              endDate
            )}` +
            (remaining_cap
              ? `\nCap Remaining: ${USD.format(remaining_cap)}`
              : "") +
            `\nReplacement Cost: ${value}\n${customDescription ?? ""}`
          const data = {
            invoice_id,
            organization_id,
            tool_id,
            quantity,
            description,
            tax_group_id,
            amount_type,
            tax_status_id,
            unit,
            unit_price,
            line_total,
            hh2_cost_code_id: costCode?.id,
          }
          return create("invoice_items", { data })
        })
      ).then(() => {
        redirect("show", "invoices", record.invoice_id)
      })
    },
    [accountingRecords, create, dataProvider, organization_id, redirect]
  )
  return (
    <SimpleForm
      onSubmit={handleCreate}
      defaultValues={{ amount_type: "Equipment" }}
    >
      <ReferenceInput
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          "deleted_at@is": null,
          status: "draft",
        }}
      >
        <SelectInput validate={required()} />
      </ReferenceInput>
      <TextInput source="description" multiline rows={3} />
      {/* <TaxGroupInput source="tax_group_id" /> */}
      <AmountTypeInput source="amount_type" />
      {/* <TaxStatusInput source="tax_status_id" /> */}
      {/* <HH2CostCodeInput source="cost_code_id" /> */}
    </SimpleForm>
  )
}
