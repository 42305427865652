import { Document, Page, Text, View } from "@react-pdf/renderer"

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

function InvoiceItemRow({
  invoiceItem,
  backgroundColor,
}: {
  backgroundColor: string
  invoiceItem: any
}) {
  return (
    <View
      key={invoiceItem.invoice_item_id}
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor,
        padding: 8,
      }}
    >
      {/* TODO: ADD HH2_Cost_code_id here */}
      <Text style={{ flex: 2, textAlign: "left" }}>
        {invoiceItem.description}
      </Text>
      <Text style={{ flex: 1 }}>
        {invoiceItem.quantity} {invoiceItem.unit ?? ""}
      </Text>
      {/* <Text style={{ flex: 1 }}>{USDollar.format(invoiceItem.unit_price)}</Text> */}
      <Text style={{ flex: 1 }}>{USDollar.format(invoiceItem.line_total)}</Text>
    </View>
  )
}

export function InvoicePdf({
  invoice,
  invoiceItems,
  customer,
  job,
  taxRate,
  organization,
}) {
  if (
    !invoice ||
    !customer ||
    !invoiceItems ||
    !job ||
    !taxRate ||
    !organization
  )
    return null
  const taxRateAmount = invoice.total_amount * (taxRate.taxPercent / 100)
  const totalAmount = invoice.total_amount + taxRateAmount
  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          fontFamily: "Helvetica",
          padding: 24,
          fontSize: 14,
          paddingBottom: 100,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <View style={{ gap: 32 }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {organization.invoice_organization_name}
                </Text>
                <Text style={{ fontSize: 12, color: "black" }}>
                  {organization.invoice_address}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Text
                  style={{ textAlign: "right", fontSize: 24, color: "black" }}
                >
                  Invoice {invoice.invoice_number}
                </Text>
                <Text
                  style={{ textAlign: "right", fontSize: 16, color: "gray" }}
                >
                  {invoice.invoice_date}
                </Text>
              </View>
            </View>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <Text style={{ fontSize: 12, color: "black" }}>To:</Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {customer.name}
                </Text>
                <Text style={{ fontSize: 12, color: "black" }}>
                  {customer.address1}
                </Text>
                {customer.address2 && (
                  <Text style={{ fontSize: 12, color: "black" }}>
                    {customer.address2}
                  </Text>
                )}
                <Text style={{ fontSize: 12, color: "black" }}>
                  {customer.city}, {customer.state} {customer.postalCode}
                </Text>
              </View>
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 12, color: "black" }}>Job:</Text>
                  <Text style={{ fontSize: 12, color: "black" }}>
                    {job.code}
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {job.name}
                </Text>
                <Text style={{ fontSize: 12, color: "black" }}>
                  {job.address1}
                </Text>
                {job.address2 && (
                  <Text style={{ fontSize: 12, color: "black" }}>
                    {job.address2}
                  </Text>
                )}
                {job.city !== "" && (
                  <Text style={{ fontSize: 12, color: "black" }}>
                    {job.city}, {job.state} {job.postalCode}
                  </Text>
                )}
              </View>
            </View>
            <View style={{ textAlign: "right" }}>
              <View
                fixed
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontFamily: "Helvetica-Bold",
                  borderBottom: "1px",
                  paddingHorizontal: 8,
                }}
              >
                <Text style={{ flex: 2, textAlign: "left" }}>Description</Text>
                <Text style={{ flex: 1 }}>Quantity</Text>
                {/* <Text style={{ flex: 1 }}>Unit Price</Text> */}
                <Text style={{ flex: 1 }}>Amount</Text>
              </View>
              {invoiceItems?.map((invoiceItem, index) => (
                <InvoiceItemRow
                  backgroundColor={index % 2 === 0 ? "#f0f0f0" : "white"}
                  invoiceItem={invoiceItem}
                />
              ))}
            </View>
          </View>
          <View style={{ gap: 16 }}>
            <View style={{ flexDirection: "column", alignItems: "flex-end" }}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "200pt",
                }}
              >
                <Text>Amount Billed</Text>
                <Text style={{ marginLeft: "10pt" }}>
                  {USDollar.format(invoice.total_amount)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "200pt",
                }}
              >
                <Text>Total Tax ({taxRate?.taxPercent}%)</Text>
                <Text style={{ marginLeft: "10pt" }}>
                  {USDollar.format(taxRateAmount)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "200pt",
                }}
              >
                <Text>Amount Due</Text>
                <Text style={{ marginLeft: "10pt" }}>
                  {USDollar.format(totalAmount)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {invoice.status !== "open" && (
          <Text
            fixed
            style={{
              position: "absolute",
              fontSize: 72,
              color: "rgba(0,0,0,0.1)",
              transform: "rotate(-45deg)",
              top: "50%",
              left: "50%",
              marginLeft: -100,
              marginTop: -50,
              borderWidth: "1px",
              borderRadius: "10px",
              padding: "10px",
              borderColor: "lightgray",
            }}
          >
            {invoice.status.toUpperCase()}
          </Text>
        )}
        <Text
          fixed
          style={{
            fontSize: 16,
            alignSelf: "center",
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          DUE {invoice.due_date}
        </Text>
        <Text
          style={{
            fontSize: 16,
            alignSelf: "center",
            position: "absolute",
            bottom: 30,
            right: 30,
          }}
          render={({ pageNumber, totalPages }) =>
            `${invoice.invoice_number} – ${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}
