import { BooleanInput, NumberInput, required, SelectInput } from "react-admin"
import { useWatch } from "react-hook-form"

export const UtilizationInput = ({ source, unitSource, enabledSource }) => {
  const track_utilization = useWatch({ name: enabledSource })

  return (
    <>
      <BooleanInput source={enabledSource} />
      {track_utilization ? (
        <div style={{ display: "flex", gap: 10 }}>
          <NumberInput source={source} />
          <SelectInput
            sx={{ top: -8 }}
            source={unitSource}
            choices={["hour", "kilometer", "mile", "minute"].map((unit) => ({
              id: unit,
              name: `${unit.charAt(0).toUpperCase() + unit.slice(1)}s`,
            }))}
            validate={required()}
          />
        </div>
      ) : null}
    </>
  )
}
