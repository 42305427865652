import { CreateInDialogButton } from "@react-admin/ra-form-layout"
import { usePDF } from "@react-pdf/renderer"
import { useEffect } from "react"
import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  UpdateButton,
  useGetList,
  useGetOne,
  useNotify,
  useRecordContext,
  WithRecord,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { TaxRateField } from "../Components/HH2/TaxRateField"
import { MoneyField } from "../Components/MoneyField"
import { InvoiceItemDatagrid } from "./InvoiceItemDatagrid"
import { InvoiceItemForm } from "./InvoiceItemForm"
import { InvoicePdf } from "./InvoicePdf"
import { SageExportInvoiceButton } from "./SageExportInvoiceButton"

function InvoiceShowActions() {
  const record = useRecordContext()
  if (!record) return null
  return (
    <TopToolbar>
      {record.status === "draft" && (
        <>
          <EditButton />
          <UpdateButton
            label="Finalize"
            data={{
              status: "open",
            }}
          />
          <DeleteButton />
        </>
      )}
      {record.status === "open" && (
        <>
          <UpdateButton
            label="Mark as Paid"
            data={{
              status: "paid",
            }}
          />

          <UpdateButton
            label="Mark as Uncollectible"
            data={{
              status: "uncollectible",
            }}
          />

          <UpdateButton
            label="Void"
            data={{
              status: "void",
            }}
          />
        </>
      )}
      <InvoicePdfButton />
      {record.status === "open" && <SageExportInvoiceButton />}
    </TopToolbar>
  )
}

const InvoicePdfButton = () => {
  const notify = useNotify()
  const invoice = useRecordContext()
  const { data: invoiceItems } = useGetList("invoice_items", {
    filter: { invoice_id: invoice?.invoice_id, "deleted_at@is": null },
  })

  const { data: customer } = useGetOne("hh2/customers", {
    id: invoice?.hh2_customer_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: job } = useGetOne("hh2/jobs", {
    id: invoice?.hh2_job_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: taxRate } = useGetOne("hh2/tax_rates", {
    id: invoice?.hh2_tax_rate_id,
    meta: { organization_id: invoice?.organization_id },
  })
  const { data: organization } = useGetOne("organizations", {
    id: invoice?.organization_id,
  })

  const [instance, updateInstance] = usePDF({
    document: InvoicePdf({
      invoice,
      invoiceItems,
      customer,
      job,
      taxRate,
      organization,
    }),
  })
  const isNotReady = !invoice || !invoiceItems || !customer || !job || !taxRate
  useEffect(() => {
    if (isNotReady) return
    updateInstance(
      InvoicePdf({
        invoice,
        invoiceItems,
        customer,
        job,
        taxRate,
        organization,
      })
    )
  }, [
    invoice,
    invoiceItems,
    updateInstance,
    customer,
    job,
    taxRate,
    organization,
    isNotReady,
  ])

  if (!invoice) {
    return null
  }

  return (
    <Button
      href={instance.url}
      disabled={instance.loading || isNotReady}
      label="Download PDF"
      LinkComponent={(props) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a {...props} download={`invoice-${invoice.invoice_number}.pdf`} />
      )}
      onClick={() => {
        if (instance.error) {
          notify(instance.error, { type: "error" })
        }
      }}
    />
  )
}

export function InvoiceShow() {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <Show actions={<InvoiceShowActions />}>
      <SimpleShowLayout>
        <ReferenceField
          source="hh2_customer_id"
          reference="hh2/customers"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="hh2_job_id"
          reference="hh2/jobs"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Account"
          source="hh2_account_id"
          reference="hh2/accounts"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="invoice_number" />
        <TextField source="status" textTransform="capitalize" />
        <TaxRateField label="Tax Rate" source="hh2_tax_rate_id" />
        <DateField source="invoice_date" />
        <DateField source="due_date" />
        <ReferenceManyField
          target="invoice_id"
          source="invoice_id"
          reference="invoice_items"
          filter={{ "deleted_at@is": null, organization_id }}
          pagination={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <WithRecord
                render={(record) => {
                  if (record.status !== "draft") return null
                  return (
                    <CreateInDialogButton
                      label="Add Item"
                      record={{ invoice_id: record.invoice_id }}
                    >
                      <InvoiceItemForm />
                    </CreateInDialogButton>
                  )
                }}
              />
              <Pagination />
            </div>
          }
          label="Items"
        >
          <WithRecord
            render={(record) => (
              <InvoiceItemDatagrid
                rowClick={record?.status === "draft" ? "edit" : false}
                bulkActionButtons={
                  record?.status === "draft" ? undefined : false
                }
              />
            )}
          />
        </ReferenceManyField>
        <MoneyField source="total_amount" />
        {/* <DateField source="created_at" />
        <DateField source="updated_at" /> */}
      </SimpleShowLayout>
    </Show>
  )
}
