import { SimpleForm } from "@react-admin/ra-rbac"
import {
  DateInput,
  Link,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  WithRecord,
  required,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { MoneyInput } from "../Components/MoneyInput"
import { PercentInput } from "../Components/PercentInput"

export function AccountingForm() {
  const [organization_id] = useOrganizationId()

  return (
    <SimpleForm defaultValues={{ organization_id }}>
      <ReferenceInput source="project_id" reference="projects">
        <SelectInput source="project_name" disabled />
      </ReferenceInput>
      <TextInput source="category" disabled />
      <ReferenceInput source="tool_id" reference="tools">
        <SelectInput source="tool_name" disabled />
      </ReferenceInput>
      <DateInput source="period_start" disabled />
      <DateInput source="period_end" disabled />
      <DateInput source="bill_date" disabled />
      <WithRecord
        render={(record) => (
          <Link resource="tools" to={`/tools/${record.tool_id}/edit`}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                marginBottom: 8,
              }}
            >
              <MoneyInput
                source="daily_rent"
                disabled
                helperText="edit tool"
                FormHelperTextProps={{ style: { color: "blue" } }}
              />
              <MoneyInput
                source="weekly_rent"
                disabled
                helperText="edit tool"
                FormHelperTextProps={{ style: { color: "blue" } }}
              />
              <MoneyInput
                source="monthly_rent"
                disabled
                helperText="edit tool"
                FormHelperTextProps={{ style: { color: "blue" } }}
              />
              <MoneyInput
                source="rental_cost"
                disabled
                helperText="edit tool"
                FormHelperTextProps={{ style: { color: "blue" } }}
              />
            </div>
          </Link>
        )}
      />
      <MoneyInput source="period_rent" disabled />
      <TextInput source="bill_type" disabled />
      <MoneyInput source="value" disabled />
      <NumberInput source="quantity" disabled />

      <MoneyInput source="capped_rent" validate={required()} />
      <MoneyInput source="remaining_cap" disabled />
      <PercentInput source="cap_percentage" disabled />
      <TextInput source="cost_code" />
    </SimpleForm>
  )
}
