import BuildIcon from "@mui/icons-material/BuildOutlined"
import BusinessIcon from "@mui/icons-material/BusinessOutlined"
import CalculateIcon from "@mui/icons-material/CalculateOutlined"
import CategoryIcon from "@mui/icons-material/CategoryOutlined"
import EngineeringIcon from "@mui/icons-material/EngineeringOutlined"
import FoundationIcon from "@mui/icons-material/FoundationOutlined"
import ConstructionIcon from "@mui/icons-material/HandymanOutlined"
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined"
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined"
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined"
import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined"
import { Resource } from "@react-admin/ra-rbac"
import { AuthLayout, SetPasswordPage } from "ra-supabase-ui-materialui"
import {
  AdminContext,
  AdminUI,
  CustomRoutes,
  defaultDataProvider,
  localStorageStore,
  Login,
} from "react-admin"
import { BrowserRouter, Route } from "react-router-dom"
import { AccountingEdit } from "./Accounting/AccountingEdit"
import { AccountingList } from "./Accounting/AccountingList"
import { BrandCreate } from "./Brands/BrandCreate"
import { BrandEdit } from "./Brands/BrandEdit"
import { BrandList } from "./Brands/BrandList"
import { BrandShow } from "./Brands/BrandShow"
import { CategoryCreate } from "./Categories/CategoryCreate"
import { CategoryEdit } from "./Categories/CategoryEdit"
import { CategoryList } from "./Categories/CategoryList"
import { CategoryShow } from "./Categories/CategoryShow"
import { Layout } from "./Components/Layout/Layout"
import { CrewMemberCreate } from "./CrewMember/CrewMemberCreate"
import { CrewMemberEdit } from "./CrewMember/CrewMemberEdit"
import { CrewMemberList } from "./CrewMember/CrewMemberList"
import { CrewMemberShow } from "./CrewMember/CrewMemberShow"
import { HH2CostCodeList } from "./HH2/HH2CostCodeList"
import { HH2EmployeeList } from "./HH2/HH2EmployeeList"
import { HH2JobList } from "./HH2/HH2JobList"
import { InvoiceCreate } from "./Invoice/InvoiceCreate"
import { InvoiceEdit } from "./Invoice/InvoiceEdit"
import { InvoiceItemCreate } from "./Invoice/InvoiceItemCreate"
import { InvoiceItemEdit } from "./Invoice/InvoiceItemEdit"
import { InvoiceItemShow } from "./Invoice/InvoiceItemShow"
import { InvoiceList } from "./Invoice/InvoiceList"
import { InvoiceShow } from "./Invoice/InvoiceShow"
import { LoginForm } from "./Login/LoginForm"
import { OtpForm } from "./Login/OtpForm"
import { OrganizationEdit } from "./Organization/OrganizationEdit"
import { OrganizationList } from "./Organization/OrganizationList"
import { ProcoreCompanyList } from "./Procore/ProcoreCompanyList"
import { ProcoreCostCodeList } from "./Procore/ProcoreCostCodeList"
import { ProcoreProjectList } from "./Procore/ProcoreProjectList"
import { ProcoreUsersList } from "./Procore/ProcoreUsersList"
import { ProjectCreate } from "./Projects/ProjectCreate"
import { ProjectEdit } from "./Projects/ProjectEdit"
import { ProjectList } from "./Projects/ProjectList"
import { ProjectShow } from "./Projects/ProjectShow"
import { authProvider } from "./Providers/authProvider"
import { dataProvider as tooltribeDataProvider } from "./Providers/dataProvider"
import { dataProvider as hh2DataProvider } from "./Providers/hh2Providers"
import { dataProvider as procoreDataProvider } from "./Providers/procoreProvider"
import { ReorderEdit } from "./Reorder/ReorderEdit"
import { ReorderList } from "./Reorder/ReorderList"
import { ReorderShow } from "./Reorder/ReorderShow"
import { ConsumablesReportList } from "./Reports/ConsumablesReport"
import { ServiceRecordCreate } from "./Service/ServiceRecord/ServiceRecordCreate"
import { ServiceRecordEdit } from "./Service/ServiceRecord/ServiceRecordEdit"
import { ServiceRecordList } from "./Service/ServiceRecord/ServiceRecordList"
import { ServiceRecordShow } from "./Service/ServiceRecord/ServiceRecordShow"
import { ServiceTemplateCreate } from "./Service/ServiceTemplate/ServiceTemplateCreate"
import { ServiceTemplateEdit } from "./Service/ServiceTemplate/ServiceTemplateEdit"
import { ServiceTemplateList } from "./Service/ServiceTemplate/ServiceTemplateList"
import { ToolServiceCreate } from "./Service/ToolService/ToolServiceCreate"
import { ToolServiceEdit } from "./Service/ToolService/ToolServiceEdit"
import { ToolServiceList } from "./Service/ToolService/ToolServiceList"
import { ToolServiceShow } from "./Service/ToolService/ToolServiceShow"
import { SetPassword } from "./SetPassword"
import { ToolCreate } from "./Tool/ToolCreate"
import { ToolEdit } from "./Tool/ToolEdit"
import { ToolList } from "./Tool/ToolList"
import { ToolShow } from "./Tool/ToolShow"
import { ToolTransfer } from "./Tool/Transfer/ToolTransfer"
import { ToolScanList } from "./ToolScans/ToolScanList"
import { useEnabledModules } from "./hooks/useEnabledModules"
import { i18nProvider } from "./i8n/i8nProvider"
import { lightTheme } from "./theme"

const store = localStorageStore()
const dataProviders = {
  procore: procoreDataProvider,
  hh2: hh2DataProvider,
}

const dataProvider = new Proxy(defaultDataProvider, {
  get: (target, name) => {
    return (resource: string, params) => {
      if (typeof name === "symbol" || name === "then") {
        return
      }
      if (resource.includes("/")) {
        // We don't use split since we might have slashes in the resource name
        const divider = resource.indexOf("/")
        const [provider, providerResource] = [
          resource.slice(0, divider),
          resource.slice(divider + 1),
        ]
        return dataProviders[provider][name](providerResource, params)
      }
      return tooltribeDataProvider[name](resource, params)
    }
  },
})

const AdminPage = () => {
  return (
    <BrowserRouter>
      <AdminContext
        i18nProvider={i18nProvider}
        theme={lightTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        store={store}
      >
        <Resources />
      </AdminContext>
    </BrowserRouter>
  )
}

function Resources() {
  const { isServiceEnabled, reportingEnabled, isLoading } = useEnabledModules()
  if (isLoading) return null
  return (
    <AdminUI
      title="Tooltribe"
      layout={Layout}
      // dashboard={Dashboard}
      loginPage={
        <Login backgroundImage="https://unsplash.com/photos/80zZ1s24Nag/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjYyNTcxODAw&force=true&w=640">
          <LoginForm />
        </Login>
      }
      requireAuth
    >
      <CustomRoutes noLayout>
        <Route
          path="/verify-code"
          element={
            <AuthLayout backgroundImage="https://unsplash.com/photos/80zZ1s24Nag/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjYyNTcxODAw&force=true&w=640">
              <OtpForm />
            </AuthLayout>
          }
        />
        <Route path={SetPasswordPage.path} element={<SetPassword />} />
      </CustomRoutes>
      <Resource
        icon={ConstructionIcon}
        name="tools"
        list={<ToolList />}
        edit={<ToolEdit />}
        create={<ToolCreate />}
        show={<ToolShow />}
        hasCreate={false}
        recordRepresentation="full_display_name"
      >
        <Route path=":toolId/transfer" element={<ToolTransfer />} />
      </Resource>
      <Resource
        icon={EngineeringIcon}
        name="crew_members"
        list={<CrewMemberList />}
        edit={<CrewMemberEdit />}
        create={<CrewMemberCreate />}
        show={<CrewMemberShow />}
        recordRepresentation="name"
      />
      <Resource
        icon={FoundationIcon}
        name="projects"
        list={<ProjectList />}
        edit={<ProjectEdit />}
        create={<ProjectCreate />}
        show={<ProjectShow />}
        recordRepresentation="project_name"
      />
      <Resource
        icon={CategoryIcon}
        name="categories"
        list={<CategoryList />}
        edit={<CategoryEdit />}
        create={<CategoryCreate />}
        show={<CategoryShow />}
        recordRepresentation="name"
      />
      <Resource
        icon={WysiwygOutlinedIcon}
        name="brands"
        list={<BrandList />}
        edit={<BrandEdit />}
        create={<BrandCreate />}
        show={<BrandShow />}
        recordRepresentation="name"
      />
      <Resource
        recordRepresentation="name"
        icon={BusinessIcon}
        name="organizations"
        list={<OrganizationList />}
        edit={<OrganizationEdit />}
        hasCreate={false}
        hasShow={false}
      />
      <Resource
        icon={InventoryOutlinedIcon}
        name="reorders"
        list={<ReorderList />}
        edit={<ReorderEdit />}
        show={<ReorderShow />}
        hasCreate={false}
        recordRepresentation={() => ""}
      />
      {isServiceEnabled && (
        <>
          <Resource
            icon={BuildIcon}
            name="service_templates"
            list={<ServiceTemplateList />}
            edit={<ServiceTemplateEdit />}
            create={<ServiceTemplateCreate />}
            recordRepresentation="name"
          />
          <Resource
            icon={BuildIcon}
            name="tool_services"
            list={<ToolServiceList />}
            edit={<ToolServiceEdit />}
            create={<ToolServiceCreate />}
            show={<ToolServiceShow />}
            recordRepresentation={() => ""}
          />
          <Resource
            icon={BuildIcon}
            name="service_records"
            list={<ServiceRecordList />}
            edit={<ServiceRecordEdit />}
            create={<ServiceRecordCreate />}
            show={<ServiceRecordShow />}
          />
        </>
      )}
      <Resource
        icon={() => (
          <img
            width="16"
            height="16"
            src="/sage.png"
            alt="Sage"
            style={{ marginLeft: 4 }}
          />
        )}
        name="hh2/jobs"
        list={<HH2JobList />}
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
      />
      <Resource
        icon={() => (
          <img
            width="16"
            height="16"
            src="/sage.png"
            alt="Sage"
            style={{ marginLeft: 4 }}
          />
        )}
        name="hh2/cost_codes"
        list={<HH2CostCodeList />}
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
      />
      <Resource
        icon={() => (
          <img
            width="16"
            height="16"
            src="/sage.png"
            alt="Sage"
            style={{ marginLeft: 4 }}
          />
        )}
        name="hh2/employees"
        list={<HH2EmployeeList />}
        hasCreate={false}
        hasShow={false}
        hasEdit={false}
      />
      <Resource
        icon={() => (
          <img
            width="16"
            height="16"
            src="/procore-bw.png"
            alt="PROCORE"
            style={{ marginLeft: 4 }}
          />
        )}
        name="procore/companies"
        list={<ProcoreCompanyList />}
      >
        <Route path=":company_id/projects" element={<ProcoreProjectList />} />
        <Route
          path=":company_id/projects/:project_id/cost_codes"
          element={<ProcoreCostCodeList />}
        />
        <Route path=":company_id/users" element={<ProcoreUsersList />} />
      </Resource>
      {(reportingEnabled.includes("monthly_accounting_report") ||
        reportingEnabled.includes("accounting_report")) && (
        <Resource
          icon={CalculateIcon}
          name="accounting"
          list={<AccountingList />}
          hasCreate={false}
          edit={<AccountingEdit />}
        />
      )}
      {reportingEnabled.includes("consumables_report") && (
        <Resource
          icon={SummarizeOutlinedIcon}
          name="consumables_report"
          list={<ConsumablesReportList />}
        />
      )}
      {(reportingEnabled.includes("monthly_accounting_report") ||
        reportingEnabled.includes("accounting_report")) && (
        <>
          <Resource
            icon={RequestQuoteOutlinedIcon}
            name="invoices"
            list={<InvoiceList />}
            create={<InvoiceCreate />}
            edit={<InvoiceEdit />}
            show={<InvoiceShow />}
            recordRepresentation="invoice_number"
          />

          <Resource
            icon={RequestQuoteOutlinedIcon}
            name="invoice_items"
            // list={<InvoiceItemList />}
            create={<InvoiceItemCreate />}
            edit={<InvoiceItemEdit />}
            show={<InvoiceItemShow />}
            recordRepresentation="invoice_date"
          />
        </>
      )}
      {/* {reporting_enabled.includes("accounting_report") && (
        <Resource
          icon={SummarizeOutlinedIcon}
          name="accounting_report"
          list={<AccountingReportList />}
        />
      )} */}

      {/* {reporting_enabled.includes("monthly_accounting_report") && (
        <Resource
          icon={SummarizeOutlinedIcon}
          name="monthly_accounting_report"
          list={<AccountingReportList />}
        />
      )} */}
      <Resource
        icon={SummarizeOutlinedIcon}
        name="tool_scans"
        list={<ToolScanList />}
      />
    </AdminUI>
  )
}
export default AdminPage
