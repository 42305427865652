import ExcelJS from "exceljs"
import { useStore } from "react-admin"
import streamSaver from "streamsaver"
import { i18nProvider } from "../i8n/i8nProvider"

const useCurrentColumns = () => {
  const [availableColumns] = useStore(
    "preferences.accounting.datagrid.availableColumns"
  )
  const [omitColumns] = useStore("preferences.accounting.datagrid.omit")
  const [numericCols] = useStore("preferences.accounting.datagrid.columns")
  if (numericCols) {
    return availableColumns?.filter(({ index }) => numericCols.includes(index))
  }
  if (!omitColumns) return availableColumns
  return availableColumns?.filter(({ source }) => !omitColumns.includes(source))
}

export function useAccountingExporter() {
  const [availableColumns] = useStore(
    "preferences.accounting.datagrid.availableColumns"
  )
  const currentColumns = useCurrentColumns()?.map(({ source }) => source)

  return async function exporter(
    accountingRecords: any[],
    fetchRelatedRecords: any
  ) {
    const projects = await fetchRelatedRecords(
      accountingRecords,
      "project_id",
      "projects"
    )
    const tools = await fetchRelatedRecords(
      accountingRecords,
      "tool_id",
      "tools"
    )

    const columns = [
      ...availableColumns.filter(
        ({ source }) => !["primary_photo", "label_photo"].includes(source)
      ),
    ]

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("Accounting Records")
    debugger
    worksheet.columns = columns.map(
      ({ source }): Partial<ExcelJS.Column> => ({
        header: i18nProvider.translate(
          `resources.accounting.fields.${source}`,
          {}
        ),
        key: source,
        width: 20,
        hidden: !currentColumns.includes(source),
        numFmt: [
          "value",
          "daily_rent",
          "weekly_rent",
          "monthly_rent",
          "rental_cost",
          "period_rent",
          "capped_rent",
          "remaining_cap",
        ].includes(source)
          ? "$#,##"
          : undefined,
      })
    )

    const rows = accountingRecords
      .filter((record) => record.deleted_at == null)
      .map((record) => {
        const {
          project_id,
          tool_id,
          period_start,
          period_end,
          bill_date,
          projects: _2,
          tools: _1,
          ...recordForExport
        } = record

        recordForExport.project_id = projects
          ? projects[project_id].project_name
          : ""
        recordForExport.tool_id = tools ? tools[tool_id].display_name : ""

        recordForExport.period_start = new Date(period_start)
        recordForExport.period_end = new Date(period_end)
        recordForExport.bill_date = new Date(bill_date)
        worksheet.addRow(recordForExport)

        return columns.map(({ source }) => recordForExport[source])
      })

    worksheet.addTable({
      name: "AccountingRecords",
      ref: `A1:${worksheet.columns.length}1`,
      headerRow: true,
      totalsRow: true,
      style: {
        theme: null,
      },
      columns: columns.map(({ source }: { index: number; source: string }) => ({
        name: i18nProvider.translate(
          `resources.accounting.fields.${source}`,
          {}
        ),
        filterButton: true,
        totalsRowFunction: ["quantity"].includes(source) ? "sum" : undefined,
      })),
      rows,
    })

    const fileStream = streamSaver.createWriteStream("accounting_records.xlsx")
    const writer = fileStream.getWriter()
    await workbook.xlsx.write(writer)
    writer.close()
  }
}
