import { Button, CardContent, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Form, useLogin, useNotify, useSafeSetState, useStore } from "ra-core"
import { useCallback } from "react"
import { useRedirect } from "react-admin"

const PREFIX = "RaLoginForm"

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}))

interface LoginFormProps {
  redirectTo?: string
  className?: string
  submitLabel: string
  children: React.ReactNode
}

interface FormData {
  email: string
}

export function SupabaseAuthForm(props: LoginFormProps) {
  const { redirectTo, className, children, submitLabel } = props
  const [loading, setLoading] = useSafeSetState(false)
  const login = useLogin()
  // const translate = useTranslate()
  const notify = useNotify()
  const [showSSO, setShowSSO] = useStore("showSSO", false)

  const redirect = useRedirect()
  const submit = useCallback(
    async (values: FormData) => {
      setLoading(true)

      try {
        await login(values, redirectTo)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error == null) {
          notify("ra.action.magic_link_sent", { type: "success" })
          redirect(`/verify-code?email=${encodeURIComponent(values.email)}`)
          return
        }
        if (
          error.message ===
          'null value in column "organization_id" of relation "user_organizations" violates not-null constraint'
        ) {
          notify("ra.auth.user_not_found", { type: "error" })
          return
        }
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "warning",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        )
      }
    },
    [setLoading, login, redirectTo, notify, redirect]
  )
  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        <h1 style={{ fontSize: 24, textAlign: "center" }}>
          <img
            src="/images/tooltribe-bw.webp"
            style={{ width: 200 }}
            alt="TOOLTRIBE"
          />
        </h1>
        {children}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            submitLabel
          )}
        </Button>
        {showSSO && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              login({
                provider: "azure",
                options: {
                  scopes: "email",
                },
              })
            }}
            fullWidth
            className={LoginFormClasses.button}
          >
            <img
              src="/images/microsoft-logo.svg"
              alt="Microsoft Logo"
              style={{ width: 20, marginRight: 8 }}
            />
            Sign in with Microsoft
          </Button>
        )}
        <Button
          variant="text"
          color="secondary"
          onClick={() => setShowSSO(!showSSO)}
          fullWidth
          className={LoginFormClasses.button}
        >
          {showSSO ? "Hide Additional Options" : "Show Additional Options"}
        </Button>
      </CardContent>
    </StyledForm>
  )
}
