import polyglotI18nProvider from "ra-i18n-polyglot"
import englishMessages from "ra-language-english"
import { mergeTranslations } from "react-admin"
// import frenchMessages from "ra-language-french"
import { raAuditLogLanguageEnglish } from "@react-admin/ra-audit-log"
import { raSupabaseEnglishMessages } from "ra-supabase-language-english"

export const i18nProvider = polyglotI18nProvider(() =>
  mergeTranslations(
    englishMessages,
    raAuditLogLanguageEnglish,
    raSupabaseEnglishMessages,
    {
      // Add your custom messages here
      ra: {
        auth: {
          user_not_found:
            "User not found, visit https://www.tooltribe.com to sign up or contact support@tooltribe.com for assistance",
        },
        action: {
          hh2: {
            jobs: {
              import: {
                button: "Import Projects from Sage",
                selected: "Import Selected Sage Projects",
                notification:
                  "Imported %{smart_count} Sage project |||| Imported %{smart_count} Sage projects",
              },
            },
            cost_codes: {
              import: {
                button: "Import Cost Codes from Sage",
                selected: "Import Selected Sage Cost Codes",
                notification:
                  "Imported %{smart_count} Sage cost code |||| Imported %{smart_count} Sage cost codes",
              },
            },
            employees: {
              import: {
                button: "Import Crew Members from Sage",
                selected: "Import Selected Sage Employees",
                notification:
                  "Imported %{smart_count} Sage employee |||| Imported %{smart_count} Sage employees",
              },
            },
          },
          procore: {
            projects: {
              import: {
                button: "Import Projects from Procore",
                selected: "Import Selected Projects",
                notification:
                  "Imported %{smart_count} Procore project |||| Imported %{smart_count} Procore projects",
              },
            },
            users: {
              import: {
                button: "Import Crew Members from Procore",
                selected: "Import Selected Crew Members",
                notification:
                  "Imported %{smart_count} crew member From Procore  |||| Imported %{smart_count} crew members from Procore",
              },
            },
            cost_codes: {
              import: {
                button: "Import Cost Codes from Procore",
                selected: "Import Selected Cost Codes",
                notification:
                  "Imported %{smart_count} Procore cost code |||| Imported %{smart_count} Procore cost codes",
              },
            },
          },
          clear_state: "Clear Status",
          inactive_status: "Set Status",
          active: "Clear Audit/Transfer/Request",
          audit: "Audit",
          transfer: "Transfer",
          mark_single_repair: "Mark Single Item Out for Repair",
          magic_link_sent: "Check your email for the magic link",
        },
      },
      // TODO: File a bug on this as we should be able to override the key
      "ra-form-layout": {
        action: { bulk_update: "Update Items" },
      },
      "ra-audit-log": {
        record_event: {
          insert: "Created",
          tool: {
            create: "Created and assigned to %{sender}",
          },
          transfer: {
            cancelled: "Cancelled %{action} from %{sender}",
            accepted: "%{recipient} accepted transfer from %{sender}",
            auditing: "Requested an audit from %{sender}",
            audited: "Audit completed by %{sender}",
            inactive: "Transfer inactive",
            requested: "Requested transfer from %{sender} to %{recipient}",
            transferring: "Transferring from %{sender} to %{recipient}",
            change_project: "Assigned to project %{project}",
            transferred: "Transferred from %{sender} to %{recipient}",
          },
          legacy_transfer: {
            add_tool: "Assigned to %{recipient}",
            transfer: "Transferred from %{sender} to %{recipient}",
          },
        },
      },
      resources: {
        tools: {
          name: "Item |||| Items",
          fields: {
            item_id: "Item ID",
            name: "Name / Model",
            category: "Category",
            "category@in": "Categories",
            quantity: "Quantity",
            display_name: "Name / Model",
            asset_tag: "Item ID",
            sender_id: "Crew Member",
            "sender_id@in": "Crew Members",
            brand: "Brand",
            "brand@in": "Brands",
            project_id: "Project",
            "project_id@in": "Projects",
            purchase_order: "Purchase Order",
            state: "Transfer State",
            description: "Description",
            value: "Unit Value",
            quantity_value: "Quantity Value",
            last_service_date: "Last Service Date",
            next_service_date: "Next Service Date",
            recipient_id: "Transferee",
            serial_number: "Serial Number",
            tool_photo: "Photo",
            primary_photo: "Photo",
            primary_photo_link: "Large Photo",
            created_at: "Added",
            updated_at: "Last Modified",
            organization_number: "Organization Number",
            is_consumable: "Consumable",
            inactive_status: "Status",
            attachment: "Attachment",
            enable_procore_daily_log: "Synch Procore Daily Logs",
            utilization: "Utilization Qty",
            rental_cost: "Re-rental Cost",
            daily_rent: "Daily Rate",
            weekly_rent: "Weekly Rate",
            monthly_rent: "Monthly Rate",
          },
          actions: {
            edit_status: "Edit Status",
          },
        },
        tool_scans: {
          name: "Scan |||| Scans",
          fields: {
            tool_id: "Item",
            scan_photo: "Photo",
            created_at: "Date",
          },
        },
        crew_members: {
          name: "Crew Member |||| Crew Members",
          fields: {
            sender_id: "Crew Member",
            enable_tool_add_notifications:
              "Send e-mail notifications to this crew member when any tools are added to the organization",
          },
        },
        projects: {
          name: "Project |||| Projects",
          fields: {
            project_name: "Name",
            sender_id: "Crew Member",
            project_type: "Type",
            project_cap_percentage: "Rental Cap",
            project_discount_percentage: "Discount",
            small_tools_value: "Small Tools Value",
            small_tools_cap_percentage: "Small Tools Cap",
          },
        },
        brands: {
          name: "Brand |||| Brands",
        },
        categories: {
          name: "Category |||| Categories",
          fields: {
            name: "Name",
            image_url: "Image",
          },
        },
        reorders: {
          name: "Reorder Point |||| Reorder Points",
          fields: {
            group_id: "Item",
            // reorder_date: "Reorder Date",
            // reorder_status: "Reorder Status",
            // reorder_notes: "Reorder Notes",
          },
        },
        organizations: {
          name: "Organization |||| Organizations",
          fields: {
            name: "Organization Name",
            logo: "Logo",
            trade: "Trade",
            allow_crew_transfer:
              "Allow crew to transfer tools to and from other crew members",
            allow_crew_invite:
              "Allow crew to add other crew members or projects",
            allow_crew_add_edit_tools: "Allow crew to add/edit tools",
            auto_number_tools: "Auto number tools",
            prefer_transfer_photo: "Display transfer photos",
            prefer_project_name: "Display project name",
            default_to_confirmation_required:
              "Default to confirmation required for transfers",
            enable_ocr: "Scan only bar codes & QR codes",
          },
        },
        project_report: {
          name: "Tools",
          fields: {
            tool_id: "Item",
            projects: { project_name: "Project" },
            tools: {
              full_display_name: "Item",
              value: "Value",
              category: "Category",
            },
          },
        },
        invoices: {
          name: "Invoice |||| Invoices",
          fields: {
            invoice_number: "Invoice Number",
            hh2_customer_id: "Customer",
            invoice_date: "Invoice Date",
            due_date: "Due Date",
            total: "Total",
          },
        },
        invoice_items: {
          tool_id: "Item",
          invoice_id: "Invoice",
          hh2_job_id: "Project",
          quantity: "Quantity",
          unit_price: "Unit Price",
          total: "Total",
        },
        consumables_report: {
          name: "Consumables",
          fields: {
            tool_id: "Item",
            consumed_date: "Consumed Date",
            "consumed_date@gte": "Consumed Date After",
            "consumed_date@lte": "Consumed Date Before",
          },
        },
        accounting: {
          name: "Rental Accounting",
          fields: {
            tool_id: "Item",
            rental_cost: "Re-rental Cost",
            period_rent: "Period Rate",
            remaining_cap: "Rental Cap Remaining",
            cap_percentage: "Rental Cap Percentage",
            bill_type: "Period",
            "bill_date@gte": "Billing Date Start",
            "bill_date@lte": "Billing Date End",
            project: "Project",
            tool: "Tool",
            period_start: "Period Start",
            period_end: "Period End",
            bill_date: "Bill Date",
            daily_rent: "Daily Rent",
            weekly_rent: "Weekly Rent",
            monthly_rent: "Monthly Rent",
            value: "Value",
            quantity: "Quantity",
            capped_rent: "Capped Rent",
            cost_code: "Cost Code",
            project_id: "Project",
            category: "Category",
          },
        },
        accounting_report: {
          name: "Accounting",
          fields: {
            tool_id: "Item",
            rental_cost: "Re-rental Cost",
            period_rent: "Period Rate",
            remaining_cap: "Rental Cap Remaining",
            cap_percentage: "Rental Cap Percentage",
            bill_type: "Period",
            "bill_date@gte": "Billing Date Start",
            "bill_date@lte": "Billing Date End",
          },
        },
        monthly_accounting_report: {
          name: "Accounting (M)",
          fields: {
            tool_id: "Item",
            rental_cost: "Re-rental Cost",
            period_rent: "Period Rate",
            remaining_cap: "Rental Cap Remaining",
            cap_percentage: "Rental Cap Percentage",
            bill_type: "Period",
            "bill_date@gte": "Billing Date Start",
            "bill_date@lte": "Billing Date End",
          },
        },
        service_templates: {
          name: "Service Type |||| Service Types",
          fields: {
            name: "Name",
            "name@ilike": "Name",
            description: "Description",
            attachment: "Attachment",
            tools: "Tools",
            is_recurring: "Recurring",
            service_interval: "Interval",
            after_interval: "Schedule After",
            reminder_interval: "Reminder",
            every_interval: "Schedule Every",
            is_utilization_based: "Utilization Based",
            service_utilization_interval: "Schedule Every",
            service_utilization_unit: "Unit",
            reminder_utilization_interval: "Reminder At",
          },
        },
        tool_services: {
          name: "Scheduled Service |||| Scheduled Service",
          fields: {
            service_template_id: "Service Type",
            due_date: "Due Date",
            "due_date@gte": "Due Date After",
            "due_date@lte": "Due Date Before",
            tools: {
              full_display_name: "Item",
              asset_tag: "Item ID",
              serial_number: "Serial Number",
              project_id: "Project",
              projects: {
                project_name: "Project",
              },
            },
            crew_member_id: "Assigned To",
            crew_members: {
              name: "Assigned To",
            },
          },
        },
        service_records: {
          name: "Service Log |||| Service Logs",
          fields: {
            tool_service_id: "Service Type",
            notes: "Notes",
            cost: "Cost",
            attachment: "Attachment",
            checklist: "Checklist",
            crew_member_id: "Completed By",
            completed_at: "Completed At",
            "completed_at@gte": "Completed After",
            "completed_at@lte": "Completed Before",
            "cost@gte": "Cost Greater Than",
            "cost@lte": "Cost Less Than",
            tools: {
              full_display_name: "Item",
              asset_tag: "Item ID",
            },
            tool_services: {
              service_template_id: "Service Type",
              "service_template_id@in": "Service Types",
            },
          },
        },
        "hh2/customers": {
          name: "Sage Customer",
        },
        "hh2/jobs": {
          name: "Sage Project",
        },
        "hh2/cost_codes": {
          name: "Sage Cost Code |||| Sage Cost Codes",
          fields: {
            code: "Code",
            name: "Name",
          },
        },
        "hh2/employees": {
          name: "Sage Employee |||| Sage Employees",
          fields: {
            code: "Employee ID",
            fullName: "Name",
            mainPhoneNumber: "Phone Number",
          },
        },
        "procore/companies": {
          name: "Procore Company |||| Procore Companies",
          fields: {
            name: "Name",
            logo_url: "Logo",
          },
        },
        "procore/projects": {
          name: "Procore Project |||| Procore Projects",
          fields: {
            display_name: "Procore Project Name",
          },
        },
        "procore/people": {
          name: "Procore Person |||| Procore People",
          fields: {
            employee_id: "Employee ID",
            first_name: "First Name",
            last_name: "Last Name",
            email: "Email",
            phone_number: "Phone Number",
            is_employee: "Employee",
          },
          filters: {
            search: "Search",
            is_employee: "Employees",
          },
        },
        "procore/users": {
          name: "Procore User |||| Procore Users",
          fields: {
            employee_id: "Employee ID",
            name: "Name",
            email_address: "Email",
            mobile_phone: "Mobile Phone",
            avatar: "Profile Picture",
            is_employee: "Employee",
          },
          filters: {
            search: "Search",
            is_employee: "Employees",
          },
        },
        "procore/cost_codes": {
          name: "Procore Cost Code |||| Procore Cost Codes",
          fields: {
            full_code: "Code",
            name: "Name",
          },
        },
      },
    }
  )
)
