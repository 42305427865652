import { SelectInput } from "react-admin"

export function AmountTypeInput(props) {
  return (
    <SelectInput
      {...props}
      choices={[
        { id: "Total Billed", name: "Total Billed" },
        { id: "Labor", name: "Labor" },
        { id: "Material", name: "Material" },
        { id: "Subcontract", name: "Subcontract" },
        { id: "Equipment", name: "Equipment" },
        { id: "Overhead", name: "Overhead" },
        { id: "Other", name: "Other" },
        { id: "Add-on", name: "Add-on" },
        { id: "Deduction", name: "Deduction" },
      ]}
    />
  )
}
