import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { EditButton, useRecordContext } from "react-admin"
import { Link } from "react-router-dom"
import { ToolState } from "./toolStates"

export const TransferButton = (props) => {
  const record = useRecordContext()
  if (!record || record?.state === ToolState.Inactive) {
    return null
  }
  return (
    <EditButton
      component={Link}
      to="./../transfer"
      icon={<ArrowForwardIcon />}
      label="ra.action.transfer"
      {...props}
    />
  )
}
