import {
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
  useDataProvider,
  useRedirect,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AmountTypeInput } from "../Components/AmountTypeInput"

export function InvoiceItemFromConsumables({ consumableRecords, projectId }) {
  const [organization_id] = useOrganizationId()
  const redirect = useRedirect()
  const [create] = useCreate()
  const dataProvider = useDataProvider()
  const handleCreate = (record) => {
    Promise.all(
      consumableRecords.map(async (consumableRecord) => {
        const { quantity, price, line_total, tool, tool_id } = consumableRecord
        debugger
        const {
          invoice_id,
          description: customDescription,
          tax_group_id,
          amount_type,
          tax_status_id,
          cost_code,
        } = record
        const description = `${tool.display_name}\n${customDescription ?? ""}`

        let costCode = null
        if (cost_code) {
          const { data: costCodes } = await dataProvider.getList(
            "hh2/cost_codes",
            {
              filter: {
                organization_id,
                // This is a fake code that will never match anything and fucking ridiculous
                q: cost_code,
              },
              pagination: { page: 1, perPage: 10 },
              sort: { field: "code", order: "ASC" },
            }
          )
          costCode = costCodes.find((code) => code.code === cost_code)
        }
        const data = {
          invoice_id,
          organization_id,
          tool_id,
          quantity,
          description,
          unit_price: price,
          line_total,
          tax_group_id,
          amount_type,
          tax_status_id,
          hh2_cost_code_id: costCode?.id,
        }
        return create("invoice_items", { data })
      })
    ).then(() => {
      redirect("show", "invoices", record.invoice_id)
    })
  }
  return (
    <SimpleForm
      onSubmit={handleCreate}
      defaultValues={{ amount_type: "Material" }}
    >
      <ReferenceInput
        meta
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          "deleted_at@is": null,
          status: "draft",
        }}
      >
        <SelectInput validate={required()} />
      </ReferenceInput>
      <TextInput source="description" multiline rows={3} />
      {/* <TaxGroupInput source="tax_group_id" /> */}
      <AmountTypeInput source="amount_type" />
      {/* No cost code input because they might not all be the same so better to edit on the lineitems */}
      {/* <HH2CostCodeInput source="hh2_cost_code_id" /> */}
      {/* <TaxStatusInput source="tax_status_id" /> */}
    </SimpleForm>
  )
}
