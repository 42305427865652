import { DateInput, required, SimpleForm, TextInput } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AccountInput } from "../Components/HH2/AccountInput"
import { CustomerInput } from "../Components/HH2/CustomerInput"
import { JobInput } from "../Components/HH2/JobInput"
import { TaxRateInput } from "../Components/HH2/TaxRateInput"

export function InvoiceForm(props) {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm
      defaultValues={{
        organization_id,
        status: "draft",
      }}
    >
      {/* <ProjectInput organization_id={organization_id} /> */}
      <CustomerInput source="hh2_customer_id" validate={required()} />
      <JobInput source="hh2_job_id" validate={required()} />
      <AccountInput source="hh2_account_id" />
      <TextInput source="invoice_number" />
      <DateInput source="invoice_date" validate={required()} />
      <DateInput source="due_date" />
      {/* <SelectInput
        source="status"
        // draft, open, paid, uncollectible, or void
        choices={[
          { id: "draft", name: "Draft" },
          { id: "open", name: "Open" },
          { id: "paid", name: "Paid" },
          { id: "uncollectible", name: "Uncollectible" },
          { id: "void", name: "Void" },
        ]}
      /> */}
      <TaxRateInput source="hh2_tax_rate_id" validate={required()} />
      {/* <CostCodeInput source="hh2_cost_code_id" /> */}
      {/* <MoneyInput source="total_amount" disabled /> */}
    </SimpleForm>
  )
}
