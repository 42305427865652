import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import BuildIcon from "@mui/icons-material/BuildOutlined"
import { MultiLevelMenu } from "@react-admin/ra-navigation"
import { IfCanAccess } from "@react-admin/ra-rbac"
import { createElement, useCallback, useMemo } from "react"
import { useResourceDefinitions, useTranslate } from "react-admin"

export function Menu() {
  const resources = useResourceDefinitions()
  const translate = useTranslate()
  const menuItem = useCallback(
    (name: string) => (
      <IfCanAccess key={name} resource={name} action="list">
        <MultiLevelMenu.Item
          name={name}
          to={`/${name}`}
          label={translate(`resources.${name}.name`, { smart_count: 2 })}
          icon={resources[name].icon && createElement(resources[name].icon)}
        />
      </IfCanAccess>
    ),
    [resources, translate]
  )

  const reports = useMemo(
    () =>
      Object.keys(resources).filter(
        (name) =>
          name.includes("_report") ||
          name.includes("accounting") ||
          name.includes("invoices")
      ),
    [resources]
  )
  const service = useMemo(
    () =>
      Object.keys(resources).filter(
        (name) =>
          name.includes("tool_services") ||
          name.includes("service_templates") ||
          name.includes("service_records")
      ),
    [resources]
  )
  return (
    <MultiLevelMenu>
      {Object.keys(resources)
        .filter(
          (name) =>
            // !name.includes("_report") &&
            !name.includes("/") &&
            !name.includes("invoice_items") &&
            !reports.includes(name) &&
            !service.includes(name)
        )
        .map(menuItem)}
      {service.length > 0 && (
        <MultiLevelMenu.Item
          label="Service"
          name="service"
          icon={<BuildIcon />}
        >
          {service.map(menuItem)}
        </MultiLevelMenu.Item>
      )}
      {reports.length > 0 && (
        <MultiLevelMenu.Item
          label="Project Billing"
          name="reports"
          icon={<AttachMoneyOutlinedIcon />}
        >
          {reports.map(menuItem)}
        </MultiLevelMenu.Item>
      )}
    </MultiLevelMenu>
  )
}
