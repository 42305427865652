import { Edit, useNotify, useRedirect, useRefresh } from "react-admin"
import { InvoiceItemForm } from "./InvoiceItemForm"

export function InvoiceItemEdit() {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = (data) => {
    notify("Changes saved")
    redirect("show", "invoices", data[0]?.invoice_id)
    refresh()
  }
  return (
    <Edit mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <InvoiceItemForm />
    </Edit>
  )
}
