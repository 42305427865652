import InputAdornment from "@mui/material/InputAdornment"
import { ComponentProps } from "react"
import { NumberInput, useInput } from "react-admin"

export function MoneyInput(props: ComponentProps<typeof NumberInput>) {
  const {
    field: { value },
  } = useInput(props)
  return (
    <NumberInput
      {...props}
      parse={(value) => (value ? parseFloat(value) : null)}
      format={(value) => (value ? parseFloat(value) : null)?.toFixed(2)}
      min={0}
      InputProps={{
        startAdornment:
          value === "" ? null : (
            <InputAdornment position="start">$</InputAdornment>
          ),
      }}
    />
  )
}
