import InputAdornment from "@mui/material/InputAdornment"
import {
  ArrayInput,
  BooleanInput,
  FileField,
  FileInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin"
import { useWatch } from "react-hook-form"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { IntervalInput } from "../../Components/IntervalInput"
import { ServiceIntervalInput } from "../../Components/ServiceIntervalInput"

export function ServiceTemplateForm() {
  const [organization_id] = useOrganizationId()

  return (
    <SimpleForm defaultValues={{ organization_id, is_recurring: true }}>
      <TextInput source="name" />
      <TextInput source="description" fullWidth rows={3} />
      <BooleanInput source="is_recurring" />
      <BooleanInput source="is_utilization_based" />
      <InnerForm />
      <FileInput source="attachment" multiple={false}>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
      <ArrayInput source="checklist">
        <SimpleFormIterator inline>
          <TextInput source="" label="Step" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}

function InnerForm() {
  const is_utilization_based = useWatch({ name: "is_utilization_based" })
  const is_recurring = useWatch({ name: "is_recurring" })
  const service_utilization_unit = useWatch({
    name: "service_utilization_unit",
  })
  if (is_utilization_based) {
    return (
      <>
        <div style={{ flexDirection: "row", alignItems: "flex-end" }}>
          <NumberInput
            label={is_recurring ? "Schedule Every" : "Schedule After"}
            source="service_utilization_interval"
            sx={{ marginTop: 1, marginRight: 1 }}
          />
          <SelectInput
            source="service_utilization_unit"
            choices={[
              { id: "hours", name: "Hours" },
              { id: "miles", name: "Miles" },
              { id: "kilometers", name: "Kilometers" },
            ]}
          />
        </div>
        <div
          style={{
            flexDirection: "row",
          }}
        >
          <NumberInput
            source="reminder_utilization_interval"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {service_utilization_unit} before due
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    )
  }
  return (
    <>
      <ServiceIntervalInput source="service_interval" />
      <IntervalInput source="reminder_interval" label="Reminder Interval" />
    </>
  )
}

// ALTER TABLE service_templates ADD COLUMN service_utilization_interval integer;
// ALTER TABLE service_templates ADD COLUMN reminder_utilization_interval integer;
// ALTER TABLE service_templates ADD COLUMN service_utilization_unit text;
