import {
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  Title,
} from "react-admin"
import { MoneyField } from "../Components/MoneyField"
import { CostCodeField } from "../Components/HH2/CodeCodeField"

export function InvoiceItemShow() {
  // const [organization_id] = useOrganizationId()
  return (
    <Show title={<Title> Invoice Item </Title>}>
      <SimpleShowLayout>
        <ReferenceField source="tool_id" reference="tools" />
        <TextField source="description" />
        <FunctionField
          source="quantity"
          textAlign="right"
          render={({ quantity, unit }) => `${quantity}${unit ?? ""}`}
        />
        <MoneyField source="unit_price" textAlign="right" />
        <MoneyField source="line_total" textAlign="right" />
        {/* <TaxGroupField source="tax_group_id" /> */}
        <TextField source="amount_type" />
        {/* <ReferenceField
          source="tax_status_id"
          reference="hh2/tax_statuses"
          queryOptions={{ meta: { organization_id } }}
        >
          <TextField source="name" />
        </ReferenceField> */}
        <CostCodeField label="Cost Code" source="hh2_cost_code_id" />
        {/* <DateField source="created_at" />
    <DateField source="updated_at" /> */}
      </SimpleShowLayout>
    </Show>
  )
}
