import { ReferenceInput, SelectInput } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function TaxRateInput({ validate, ...props }: any) {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <ReferenceInput
      {...props}
      label="Tax Rate"
      reference="hh2/tax_rates"
      filter={{ organization_id }}
      queryOptions={{ meta: { organization_id } }}
    >
      <SelectInput
        validate={validate}
        label="Tax Rate"
        optionText={({ name, taxPercent }) => `${name} (${taxPercent}%)`}
      />
    </ReferenceInput>
  )
}
