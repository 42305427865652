import { useEffect } from "react"
import { scan } from "react-scan"
import AdminPage from "./admin/AdminPage"
import "./App.css"

function App() {
  useEffect(() => {
    // Make sure to run react-scan only after hydration
    scan({
      enabled: global.__DEV__,
    })
  }, [])
  return <AdminPage />
}

export default App
