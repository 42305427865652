import { FunctionField, ReferenceField } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function CostCodeField(props) {
  const [organization_id] = useOrganizationId()
  return (
    <ReferenceField
      {...props}
      label="Cost Code"
      source="hh2_cost_code_id"
      reference="hh2/cost_codes"
      queryOptions={{ meta: { organization_id } }}
    >
      <FunctionField render={({ code, name }) => `${code} ${name}`} />
    </ReferenceField>
  )
}
