import {
  Button,
  NumberInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin"
import { useFormContext, useWatch } from "react-hook-form"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { AmountTypeInput } from "../Components/AmountTypeInput"
import { MoneyInput } from "../Components/MoneyInput"

export function InvoiceItemForm() {
  const [organization_id] = useOrganizationId()

  return (
    <SimpleForm values={{ organization_id }}>
      {/* <ReferenceInput
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          // project_id: projectId, TODO: projectId is not defined
          "deleted_at@is": null,
        }}
      /> */}
      <TextInput source="description" multiline rows={3} />
      {/* <TaxGroupInput source="tax_group_id" /> */}
      <AmountTypeInput source="amount_type" />
      {/* <TaxStatusInput source="tax_status_id" /> */}
      {/* <CostCodeInput source="hh2_cost_code_id" /> */}
      <MoneyInput source="unit_price" />
      <NumberInput source="quantity" />
      <TotalAmountInput source="line_total" />
    </SimpleForm>
  )
}

const USD = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})
function TotalAmountInput(props) {
  const { setValue } = useFormContext()

  const record = useRecordContext()
  const [unit_price = record?.unit_price, quantity = record?.quantity, total] =
    useWatch({
      name: ["unit_price", "quantity", props.source],
    })
  const helperText =
    total === unit_price * quantity ||
    Number.isNaN(unit_price * quantity) ? null : (
      <span>
        Uncapped total is
        <Button
          onClick={() => setValue(props.source, unit_price * quantity)}
          label={USD.format(unit_price * quantity)}
        />
      </span>
    )
  return <MoneyInput helperText={helperText} {...props} />
}
