import jsonExport from "jsonexport/dist"
import { downloadCSV } from "react-admin"

export async function serviceRecordsExporter(serviceRecords: any[]) {
  const serviceRecordsForExport = await Promise.all(
    serviceRecords.map((serviceRecord) => {
      const {
        id,
        tool_id,
        crew_member_id,
        organization_id,
        created_at,
        updated_at,
        completed_at,
        deleted_at,
        tools,
        crew_members,
        service_record_id,
        tool_service_id,
        tool_services,
        checklist,
        attachment,
        ...serviceRecordForExport
      } = serviceRecord

      serviceRecordForExport.item_id = tools?.asset_tag ?? ""
      serviceRecordForExport.item = tools?.name ?? ""
      serviceRecordForExport.crew_member = crew_members ? crew_members.name : ""
      serviceRecordForExport.service_type =
        tool_services?.service_templates?.name ?? ""

      serviceRecordForExport.created_at = new Date(created_at).toLocaleString()
      serviceRecordForExport.updated_at = new Date(updated_at).toLocaleString()
      serviceRecordForExport.completed_at = new Date(
        completed_at
      ).toLocaleString()

      return serviceRecordForExport
    })
  )

  const csv = await jsonExport(serviceRecordsForExport, {
    headers: [
      "item_id",
      "item",
      "service_type",
      "completed_at",
      "completed_utilization",
      "crew_member",
      "notes",
      "cost",
      "created_at",
      "updated_at",
    ], // order fields in the export
  })

  downloadCSV(csv, "service_records_report")
}
