import {
  EditButton,
  ExportButton,
  TopToolbar,
  useRecordContext,
} from "react-admin"
import { useToolsExporter } from "./toolsExporter"

export function ActionButtonsWithExport({
  exportTarget,
  children,
}: {
  exportTarget: string
  children?: React.ReactNode
}) {
  const record = useRecordContext()

  const toolsExporter = useToolsExporter()
  if (!record) return null
  return (
    <TopToolbar>
      <EditButton />
      <ExportButton
        exporter={toolsExporter}
        resource="tools"
        filterValues={{ [exportTarget]: record.id }}
      />
      {children}
    </TopToolbar>
  )
}
