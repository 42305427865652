import { BulkUpdateFormButton } from "@react-admin/ra-form-layout"
import { uniq } from "lodash-es"
import { useMemo } from "react"
import {
  AutocompleteInput,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  List,
  ListActions,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { MoneyField } from "../Components/MoneyField"
import { PercentField } from "../Components/PercentField"
import { InvoiceItemFromAccounting } from "../Invoice/InvoiceItemFromAccounting"
import { useAccountingExporter } from "./accountingExporter"

const AccountingFilters = (organization_id: string) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="tool"
    reference="tools"
    source="tool_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="display_name"
      optionText="display_name"
      filterToQuery={(query) => ({ "display_name@ilike": query })}
    />
  </ReferenceInput>,
  <DateInput source="bill_date@gte" />,
  <DateInput
    source="bill_date@lte"
    parse={(date: Date | null) => {
      date?.setHours?.(23, 59, 59, 999)
      return date
    }}
  />,
]

const AccountingBulkActionButtons = (props) => {
  const { data, selectedIds } = useListContext()
  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )

  const selectedProjectIds = uniq(
    selectedRecords.map((record) => record.project_id)
  )
  return (
    <BulkUpdateFormButton
      {...props}
      label="Send to Invoice"
      disabled={selectedProjectIds.length !== 1}
    >
      <InvoiceItemFromAccounting
        accountingRecords={selectedRecords}
        // projectId={selectedProjectIds[0]}
      />
    </BulkUpdateFormButton>
  )
}
export function AccountingList() {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => AccountingFilters(organization_id),
    [organization_id]
  )
  const accountingExporter = useAccountingExporter()
  return (
    <List
      filter={{
        organization_id,
      }}
      exporter={accountingExporter}
      filters={filters}
      actions={
        <TopToolbar sx={{ alignItems: "center" }}>
          <SelectColumnsButton style={{ marginTop: 5 }} />
          {/* {TODO: File a bug, the RBAC exporter doesn't work} */}
          <ListActions exporter={false} />
          <ExportButton exporter={accountingExporter} maxResults={10_000} />
        </TopToolbar>
      }
    >
      <DatagridConfigurable
        rowClick="edit"
        bulkActionButtons={<AccountingBulkActionButtons />}
      >
        <ReferenceField source="project_id" reference="projects" link="show" />
        <TextField source="category" />
        <ReferenceField source="tool_id" reference="tools" link="show" />
        <DateField source="period_start" />
        <DateField source="period_end" />
        <DateField source="bill_date" />
        <MoneyField source="daily_rent" prefix="$" />
        <MoneyField source="weekly_rent" />
        <MoneyField source="monthly_rent" />
        <TextField source="rental_cost" />
        <MoneyField source="period_rent" />
        <TextField source="bill_type" />
        <TextField source="value" />
        <NumberField source="quantity" />
        <MoneyField source="capped_rent" />
        <MoneyField source="remaining_cap" />
        <PercentField source="cap_percentage" />
        <TextField source="cost_code" />
      </DatagridConfigurable>
    </List>
  )
}
