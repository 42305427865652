import { BulkUpdateFormButton } from "@react-admin/ra-form-layout"
import { uniq } from "lodash-es"
import { useMemo } from "react"
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceInput,
  TextField,
  useListContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { MoneyField } from "../Components/MoneyField"
import { InvoiceItemFromConsumables } from "../Invoice/InvoiceItemFromConsumables"
import { consumablesReportExporter } from "./consumablesReportExporter"

const ConsumablesReportFilters = (organization_id) => [
  <ReferenceInput
    key="project"
    reference="projects"
    source="project_id"
    sort={{ field: "updated_at", order: "DESC" }}
    filter={{
      organization_id,
      "deleted_at@is": null,
    }}
    perPage={100}
    alwaysOn
  >
    <AutocompleteInput
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({ "project_name@ilike": query })}
    />
  </ReferenceInput>,
  <DateInput source="consumed_date@gte" />,
  <DateInput
    source="consumed_date@lte"
    parse={(date: Date | null) => {
      date?.setHours?.(23, 59, 59, 999)
      return date
    }}
  />,
]

const ConsumablesBulkActionButtons = (props) => {
  const { data, selectedIds } = useListContext()
  const selectedRecords = data.filter((record) =>
    selectedIds.includes(record.id)
  )

  const selectedProjectIds = uniq(
    selectedRecords.map((record) => record.project_id)
  )
  return (
    <BulkUpdateFormButton
      {...props}
      label="Send to Invoice"
      disabled={selectedProjectIds.length !== 1}
    >
      <InvoiceItemFromConsumables
        consumableRecords={selectedRecords}
        projectId={selectedProjectIds[0]}
      />
    </BulkUpdateFormButton>
  )
}

export const ConsumablesReportList = () => {
  const [organization_id] = useOrganizationId()
  const filters = useMemo(
    () => ConsumablesReportFilters(organization_id),
    [organization_id]
  )
  return (
    <List
      exporter={consumablesReportExporter}
      sort={{ field: "consumed_date", order: "DESC" }}
      filter={{
        "select@": "*,tool!inner(display_name),project!inner(project_name)",
        organization_id,
      }}
      filters={filters}
    >
      <Datagrid bulkActionButtons={<ConsumablesBulkActionButtons />}>
        <TextField source="project.project_name" />
        <TextField source="tool.display_name" />
        <TextField source="quantity" />
        <DateField showTime source="consumed_date" />
        <MoneyField source="value" />
        <MoneyField source="price" />
        <MoneyField source="line_total" />
      </Datagrid>
    </List>
  )
}
