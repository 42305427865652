import { ReferenceInput, SelectInput } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function AccountInput(props) {
  const [organization_id] = useOrganizationId()
  if (!organization_id) return null
  return (
    <ReferenceInput
      {...props}
      label="Account"
      reference="hh2/accounts"
      filter={{ organization_id, q: "70-4" }} // TODO: generalize hard-coded q
      queryOptions={{ meta: { organization_id } }}
    >
      <SelectInput
        label="Account"
        optionText={({ code, name }) => `${code} ${name}`}
      />
    </ReferenceInput>
  )
}
