import { Edit } from "@react-admin/ra-rbac"
import { ToolForm } from "./ToolForm"

export const ToolEdit = () => {
  return (
    <Edit actions={null}>
      <ToolForm />
    </Edit>
  )
}
