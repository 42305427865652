import { Edit, SimpleForm } from "@react-admin/ra-rbac"
import { useEffect } from "react"
import {
  BooleanInput,
  ImageField,
  ImageInput,
  SelectInput,
  TextInput,
  useRecordContext,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { PercentInput } from "../Components/PercentInput"

export function OrganizationEdit() {
  return (
    <Edit>
      <SetOrgToRecordOrg>
        <SimpleForm>
          <ImageInput source="logo">
            <ImageField source="src" />
          </ImageInput>
          <TextInput source="name" />
          <SelectInput
            source="trade"
            choices={[
              { id: "concrete", name: "Concrete" },
              { id: "steel", name: "General Contractor" },
              { id: "electrical", name: "Electrical" },
              { id: "mechanical", name: "Mechanical" },
              { id: "plumbing", name: "Plumbing" },
              { id: "waste", name: "Waste Management" },
              { id: "other", name: "Other" },
            ]}
          />
          <PercentInput source="markup_percentage" />
          <BooleanInput source="allow_crew_transfer" />
          <BooleanInput source="allow_crew_invite" />
          <BooleanInput source="allow_crew_add_edit_tools" />
          <BooleanInput source="prefer_transfer_photo" />
          <BooleanInput source="prefer_project_name" />
          <BooleanInput source="default_to_confirmation_required" />
          <BooleanInput
            source="enable_ocr"
            // we actually want to store the opposite of the value
            parse={(v) => !v}
            format={(v) => !v}
          />
        </SimpleForm>
      </SetOrgToRecordOrg>
    </Edit>
  )
}

const SetOrgToRecordOrg = ({ children }) => {
  const { id } = useRecordContext()
  const [, setOrganizationId] = useOrganizationId()
  useEffect(() => {
    if (!id) return
    setOrganizationId(id as string)
  }, [id, setOrganizationId])
  return children
}
