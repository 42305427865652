import { AutocompleteInput, ReferenceInput } from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"

export function CustomerInput({ validate, ...props }) {
  const [organization_id] = useOrganizationId()
  return (
    <ReferenceInput
      {...props}
      source="hh2_customer_id"
      reference="hh2/customers"
      filter={{ organization_id }}
      queryOptions={{ meta: { organization_id } }}
    >
      <AutocompleteInput
        validate={validate}
        label="resources.hh2/customers.name"
        optionText="name"
      />
    </ReferenceInput>
  )
}
